import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css" // Slick styles
import "slick-carousel/slick/slick-theme.css" // Slick theme styles
import ImageRenderer from "../atoms/ImageRenderer"
import ImageUrlRenderer from "../atoms/ImageUrlRenderer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons"

const ImageSlider = ({ images }) => {
  // Slick slider settings
  const settings = {
    dots: true, // Enable pagination dots
    infinite: true, // Loop the slides
    speed: 500, // Transition speed in ms
    slidesToShow: 1, // Show one slide at a time
    slidesToScroll: 1, // Scroll one slide at a time
    arrows: true, // Show navigation arrows
    adaptiveHeight: true, // Adjust slide height based on content,
    prevArrow: (
      <button className="slick-prev">
        <FontAwesomeIcon icon={faAngleLeft} className="!w-[20px] !h-[20px]" />{" "}
        {/* Left Arrow */}
      </button>
    ),
    nextArrow: (
      <button className="slick-next">
        <FontAwesomeIcon icon={faAngleRight} className="!w-[20px] !h-[20px]" />{" "}
        {/* Right Arrow */}
      </button>
    ),
  }

  return (
    <div className="cc-section--horizontal-space property-image-slider !max-w-[1260px] !p-0">
      <Slider {...settings}>
        {images?.map((image, index) => (
          <div
            key={index}
            className="inner-property--featuered-image-wrap px-6 pb-6 pt-12 md:py-12 lg:p-12"
          >
            <div className="relative">
              <div className="rounded-image full-width-images-wrapper">
                <ImageRenderer img={image} alt={`Property Image`} />
              </div>
              <div className="inner-property--featuered-image-design-image-right-top max-w-[50px] md:max-w-[100%] absolute right-[-15px] md:right-[-25px] lg:right-[-50px] top-[-20px] md:top-[-20px] lg:top-[-45px] lg:top-[-40px]">
                <ImageRenderer
                  img={`contact--form-design-image-top-left`}
                  alt={`contact--form-design-image-top-left`}
                />
              </div>
              <div className="inner-property--featuered-image-design-image-left-bottom max-w-[200px] md:max-w-[100%] absolute left-[-15px] md:left-[-22px] lg:left-[-40px] bottom-[-15px] md:bottom-[-50px] z-[-1]">
                <ImageRenderer
                  img={`blog-inner--oval-shape`}
                  alt={`blog-inner--oval-shape`}
                />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default ImageSlider
